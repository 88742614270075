import React from "react"
import { Link, graphql } from "gatsby"
// Components
import { SectionTitle } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import JobContent from "../components/JobContent"
import { Seo } from "../components/seo"
const RecommendedJobsTemplate = props => {
  // const dataJobs = useJobs("Japanese")
  const dataJobs = props.data.allStrapiJobs.nodes
  const {
    Title,
    Content,
    DatePublication,
    LastUpdate,
    Thumb,
    Top5Jobs,
    Keywords,
    MetaDescription,
    Slug,
  } = props.data.allStrapiTopJobs.nodes[0]
  const typeJob = "osusume"  
  return (
    <>
      <Seo
        title={`${Title} | メキシコの求人・転職・就職情報【QUICK GLOBAL MEXICO】`}
        image={Thumb ? Thumb.publicURL : ""}
        description={MetaDescription}
        author={`919mexico.com`}
        url={`https://www.919mexico.com/osusume${
          Slug !== null
            ? Slug
            : "https://www.919mexico.com/category/オススメのメキシコ求人"
        }`}
        keywords={[Keywords]}
      />
      <section className="container jobs-listing">
        <div className="row">
          <div className="col-lg-9">
            <div className="jobs-listing__top-listing">
              <SectionTitle>{Title}</SectionTitle>
              <hr className="u-line-bottom" />
              <p className="info-date">
                投稿日 : {DatePublication} &emsp; 更新日 : {LastUpdate} &emsp;
                カテゴリー :
                <Link
                  to="/category/オススメのメキシコ求人"
                  className="linked-tag"
                >
                  オススメのメキシコ求人
                </Link>
              </p>
              <p
                className="jobs-listing__top-listing--text-description"
                dangerouslySetInnerHTML={{ __html: Content }}
              ></p>
            </div>
            <h3 className="title-top5">{Title}</h3>
            <div className="jobs-listing__recommended-jobs--top-5-nocolor">
              <ul>
                {Top5Jobs.map((job, i) => (
                  <li key={i}>
                    <Link to={`#matome0${i + 1}`} >
                      {job.Title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="jobs-listing__job-info jobs-listing__top-5-jobs">
              {Top5Jobs.map((top, i) => (
                <>
                  <h2 id={`matome0${i + 1}`} key={i}>
                    {i + 1}, {top.Title}
                  </h2>
                  {dataJobs.map((mapdata, j) => (
                    <>
                      {top.job ? (
                        <>
                          {top.job.Manualid === mapdata.Manualid ? (
                            <JobContent
                              key={j}
                              jobs={mapdata}
                              typeJob={typeJob}
                            />
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ))}
                </>
              ))}
            </div>
            <div className="text-support">
              弊社でのサポートの流れは「
              <Link to="/process">お申し込みの流れ（サポートの流れ）</Link>
              」をご覧ください。
            </div>
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default RecommendedJobsTemplate

export const query = graphql`
  query RecommendedJobQuery($slug: String) {
    allStrapiTopJobs(filter: { Slug: { eq: $slug } }) {
      nodes {
        Content
        DatePublication(formatString: "YYYY年 MM 月 DD日")
        LastUpdate(formatString: "YYYY年 MM 月 DD日")
        Keywords
        MetaDescription
        Slug
        Thumb {
          publicURL
          childImageSharp {
            fluid {
              src
            }
          }
        }
        Title
        Top5Jobs {
          Title
          job {
            Manualid
          }
        }
      }
    }
    allStrapiJobs(sort: { fields: DatePublication, order: DESC } filter:{Language: { eq: "Japanese" }}) {
      nodes {
        JobDescription
        Allowances
        HolidayVacation
        CompanyProfile
        Insurance
        Manualid
        Salary
        SalaryIncrease
        Title
        WorkingTime
        Archived
        published_at(formatString: "YYYY年 MM月 DD日")
        strapiId
        MandatoryRequirement
        WelcomeRequirement
        NewGraduates {
          Imposible
          Negotiable
          Posible
        }
        VisaSupport {
          None
          ThereRegulation
          Yes
        }
        JobCategorie
        LocationDescription
        DatePublication
        locations {
          name
          # location_groups {
          #   SubLocation
          # }
        }
        marks {
          name
        }
        types {
          name
          image {
            publicURL
          }
        }
      }
    }
  }
`
